* {
  padding: 0;
  margin: 0;
}
html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  user-select: none;
  @media only screen and (max-width: 700px) {
    overflow-x: hidden;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ScrollBar */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: #2ec4b6;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: aqua;
  /* opacity: 1; */
}
